import { RoleUniqueNames } from '@melio/platform-api';
import { BillsEmailInboxDashboardView, VendorDetailsFormType } from '@melio/platform-provider';

import { extendDefaultConfig } from '@/configByPartner/partnerConfig.utils';
import capOneLogo from '@/images/capone/capOneAvatarLogo.svg';
import { fiservLinks } from '@/links';

export const fiservConfig = extendDefaultConfig({
  default: {
    tabTitle: 'Pay Bills',
    analytics: {
      blockedEventDestinations: ['Braze'],
    },
    settings: {
      expiredSessionTime: 10,
      partnerProductName: 'Pay Bills',
      payment: {
        allowNotifyingVendor: false,
        editing: {
          defaultToManagedAccountPreferredDeliveryMethod: true,
          shouldCheckFundingSourceEntitlements: true,
        },
      },
      batchPayments: {
        showZeroFees: false,
        backgroundColor: 'white',
        headerVariant: 'dark',
        showDeliverySpeedColumn: false,
        showVendorVerifiedDeliveryMethodTooltip: false,
        useMinimalHeaderDesign: true,
        showLateIndicator: false,
        showFeesTooltipTitle: false,
        closeButtonOnLeft: true,
        showVendorDetails: true,
        showRelativeDates: true,
        columnActions: {
          deliveryDate: {
            disabled: ['dueDate'],
          },
        },
      },
      EBillLegalDisclaimer: {
        enabled: true,
        flagName: 'ebills-legal-disclaimers',
      },
      PaymentNoteToSelf: {
        flagName: 'platform-payment-note-to-self',
        enabled: true,
      },
      ScannedInvoiceDisclaimer: {
        flagName: 'platform-feature-scanned-invoice-disclaimers',
        enabled: true,
      },
      VendorGroups: {
        enabled: true,
        flagName: 'platform-vendor-groups',
      },
      PlatformMultipleVendorsWithTheSameName: {
        flagName: 'platform-multiple-vendors-with-the-same-name',
        enabled: true,
      },
      fundingSourcePolicy: {
        'bank-account': {
          fundingSourceGroup: 'bank-account',
          read: true,
          write: true,
        },
        credit: {
          fundingSourceGroup: 'credit',
          read: true,
          write: true,
        },
        debit: {
          fundingSourceGroup: 'debit',
          read: true,
          write: true,
        },
      },
      uploadBill: {
        shouldShowMultipleBillUploadIndication: false,
      },
      trustedPartners: ['Mastercard', 'Quickbooks', 'Visa'],
      supportEmail: 'capitalonevendorpayments@melio.com',
      eoyCheck: {
        enabled: true,
        promoteFastCheck: true,
        link: 'https://capitalonevendorpayments.zendesk.com/hc/en-us/articles/6501485721372',
      },
      helpCenterUrl: 'https://capitalonevendorpayments.zendesk.com/hc/en-us',
      isUnilateralDeliveryMethodSupported: false,
      billsEmailInboxDashboardView: BillsEmailInboxDashboardView.IconAndLink,
      isPaymentApprovalLimitEnabled: true,
      showApproveActionButton: false,
      isDeclinePaymentReasonEnabled: false,
      vendor: {
        collectedDetails: 'extended',
        requiredDirectoryFields: [
          'accountNumber',
          'contact.phoneNumber',
          'contact.address.line1',
          'contact.address.city',
          'contact.address.state',
          'contact.address.postalCode',
        ],
        vendorSelect: {
          createVendor: {
            showVendorDetailsForm: true,
            vendorDetailsFormType: VendorDetailsFormType.Drawer,
          },
        },
        createVendor: {
          hasSkipDeliveryMethodButton: false,
          canAddVendorWithTheSameName: true,
          companyNameFormatRegexp: /^[ a-zA-Z0-9!"$%&'()*+,\-'./\\:;=?@]+$/,
          companyNameMaxLength: 32,
          companyNameMinLength: 2,
          shouldSwitchToExtendedFormOnApiErrors: true,
          shouldShowBankDetailsFields: true,
          shouldShowAddressDetailsBanner: true,
        },
        forms: {
          shouldUseZipCodeMask: true,
          shouldUseSearchVendorSectionLabel: false,
          shouldUseTooltipsForManagedVendorForm: false,
        },
      },
      isMultiColoredAvatarsEnabled: false,
      showCheckMarkIconForManagedVendor: false,
      singlePaymentStepLayout: {
        backgroundColor: 'white',
      },
      InvalidVendorBankAccountModal: {
        enabled: true,
        flagName: 'platform-invalid-vendor-bank-account-modal',
      },
      ApprovalWorkflowPendingApprovers: {
        enabled: false,
      },
      PayDashboardTimelineDisableDates: {
        flagName: 'pay-dashboard-timeline-disable-dates',
        enabled: true,
      },
      collaborator: {
        rolesOrderMapping: {
          [RoleUniqueNames.FiservLevel1]: 1,
          [RoleUniqueNames.FiservLevel2]: 2,
          [RoleUniqueNames.FiservLevel3]: 3,
        },
      },
    },
    services: {
      forethoughtApiKey: '74ba8f52-1c1e-4b60-8b1e-bc74bcdd4a0d',
      zendeskKey: 'f7a6bedc-a1d0-49ef-855c-8297879a42d1',
      zendeskWorkflow: 'Cap One',
    },
    tutorialVideo: '',
    zendesk: {
      headerColor: '#C3C4C2',
      resultListsColor: '#C3C4C2',
      themeColor: '#C3C4C2',
      logo: capOneLogo,
      title: 'Vendor Payments Support',
    },
    links: fiservLinks,
  },
  prod: {
    services: {
      tabapay: {
        card: {
          url: 'https://sso.tabapay.com/SSOMelioCap1.html',
          iframeHeight: [430, 380],
          iframeWidth: '100%',
        },
        credit: {
          url: 'https://sso.tabapay.com/SSOMelioCap1.html?cards=visa,mastercard',
          iframeHeight: [430, 380],
          iframeWidth: '100%',
        },
        debit: {
          url: 'https://sso.tabapay.com/SSOMelioCap1.html',
          iframeHeight: [430, 380],
          iframeWidth: '100%',
        },
      },
    },
    links: {
      failedToCollectSupport:
        'https://paybillsbymelio.zendesk.com/hc/en-us/articles/5339224613020-Resolving-unsuccessful-payments-in-Pay-Bills',
    },
  },
  dev: {
    services: {
      tabapay: {
        card: {
          url: 'https://sso.sandbox.tabapay.com:8443/SSOMelioCap1.html',
          iframeHeight: [430, 380],
          iframeWidth: '100%',
        },
        credit: {
          url: 'https://sso.sandbox.tabapay.com:8443/SSOMelioCap1.html?cards=visa,mastercard',
          iframeHeight: [430, 380],
          iframeWidth: '100%',
        },
        debit: {
          url: 'https://sso.sandbox.tabapay.com:8443/SSOMelioCap1.html',
          iframeHeight: [430, 380],
          iframeWidth: '100%',
        },
      },
    },
    links: {
      failedToCollectSupport:
        'https://paybillsbymelio.zendesk.com/hc/en-us/articles/5339224613020-Resolving-unsuccessful-payments-in-Pay-Bills',
    },
  },
});
